import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
export const MENU = 'menu'
export const MENU_SET_OPEN = `${MENU}/SET_OPEN`
export const MENU_TOGGLE = `${MENU}/TOGGLE`

export const state = () => ({
  menuOpen: false,
  darkMode: false
})

export type UiState = ReturnType<typeof state>

export const getters: GetterTree<UiState, RootState> = {
}

export const mutations: MutationTree<UiState> = {
}

export const actions: ActionTree<UiState, RootState> = {
}
