
export default {
  name: 'UiAddressFooterList',
  fetchOnServer: true,
  data () {
    return {
      addresses: []
    }
  },
  async fetch () {
    if (!this.addresses.length) {
      const { useContent } = await import('@/datalayer/addresses')
      this.addresses = await useContent(this)
    }
  }
}
