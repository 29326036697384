import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
export const MENU = 'menu'
export const MENU_SET_OPEN = `${MENU}/SET_OPEN`
export const MENU_TOGGLE = `${MENU}/TOGGLE`
export const MENU_SET_PINNED = `${MENU}/SET_PINNED`
export const MENU_SET_UNPINNED = `${MENU}/SET_UNPINNED`

export const state = () => ({
  menuOpen: false,
  darkMode: false,
  menuPinned: true
})

export type UiState = ReturnType<typeof state>

export const getters: GetterTree<UiState, RootState> = {
  isMenuOpen: state => state.menuOpen,
  isMenuClosed: state => !state.menuOpen,
  isMenuUnpinned: state => !state.menuPinned,
  isMenuPinned: state => state.menuPinned
}

export const mutations: MutationTree<UiState> = {
  [MENU_SET_OPEN] (state: UiState, menuOpen: boolean) {
    state.menuOpen = menuOpen
  },
  [MENU_TOGGLE] (state: UiState) {
    state.menuOpen = !state.menuOpen
  },
  [MENU_SET_PINNED] (state: UiState) {
    state.menuPinned = true
  },
  [MENU_SET_UNPINNED] (state: UiState) {
    state.menuPinned = false
  }
}

export const actions: ActionTree<UiState, RootState> = {
  menuToggle ({ commit }) {
    commit(MENU_TOGGLE)
  },
  menuClose ({ commit }) {
    commit(MENU_SET_OPEN, false)
  },
  menuOpen ({ commit }) {
    commit(MENU_SET_OPEN, true)
  },
  menuPinned ({ commit }) {
    commit(MENU_SET_PINNED)
  },
  menuUnpinned ({ commit }) {
    commit(MENU_SET_UNPINNED)
  }
}
