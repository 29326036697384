import throttle from 'lodash/throttle'
const sleep = ms => new Promise(resolve  => setTimeout(resolve, ms));

export default {
  methods: {
    handleScroll: throttle(function() {
      this.triggerAnimationOnScroll()
    }, 100),
    triggerAnimationOnScroll() {
      if (process.client) {
        const windowHeight = window.innerHeight
        const elementsWithAnimations = Array.from(document.querySelectorAll('.has-anim:not(.show)'))
        if (elementsWithAnimations.length) {
          for (let i = 0; i < elementsWithAnimations.length; i++) {
            const elementWithAnimations = elementsWithAnimations[i]
            const elementBounding = elementWithAnimations.getBoundingClientRect()
            const elementOffset = elementBounding.top + (elementBounding.height / 6)
            const isVisible = (elementOffset <= windowHeight)
            if (isVisible) {
              elementWithAnimations.classList.add('show')
              elementWithAnimations.classList.add('show-'+i)
            }
          }
        }
      }
    },
    registerTriggerAnimationOnScroll() {
      if (process.client) {
        window.addEventListener('scroll', this.handleScroll)
      }
    },
    removeScrollHandler() {
      if (process.client) {
        window.removeEventListener('scroll', this.handleScroll)
      }
    }
  },
  mounted () {
    if (process.client) {
      this.$nextTick(async () => {
        this.registerTriggerAnimationOnScroll()
        await sleep(110)
        this.handleScroll()
      })
    }
  },
  updated () {
    if (process.client) {
      this.$nextTick(async () => {
        this.registerTriggerAnimationOnScroll()
        await sleep(110)
        this.handleScroll()
      })
    }
  },
  beforeDestroy() {
    if (process.client) {
      this.removeScrollHandler()
    }
  }
}
