
export default {
  name: 'FooterBottom',
  props: {
    performanceScore: {
      type: String,
      default: ''
    }
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
