
import Animation from '@/mixins/animation'

export default {
  name: 'ErrorLayout',
  mixins: [
    Animation
  ],
  props: {
    error: {
      type: Object,
      default: null,
      require: true
    }
  },
  head () {
    let normalisedPath = this.$route.path
    normalisedPath = normalisedPath.replace(/\/$/, '')
    normalisedPath = (normalisedPath.endsWith('/') ? normalisedPath.slice(0, -1) : normalisedPath)

    normalisedPath = normalisedPath.includes('/tags/') ? normalisedPath.slice(0, normalisedPath.indexOf('/tags/')) : normalisedPath

    return {
      titleTemplate: '%s - AYKO',
      link: [
        {
          rel: 'canonical',
          href: normalisedPath ? (this.$config.NUXT_URL + normalisedPath) : (this.$config.NUXT_URL + '/')
        }
      ]
    }
  },
  computed: {
    pageTitle () {
      if (error.title) {
        return error.title
      } else if (error.statusCode) {
        return '404 Page not found'
      } else {
        return 'Sorry an unknown error has occurred'
      }
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.history.scrollRestoration = 'auto';
    }
  },
  methods: {
    previousPage () {
      this.$router.back()
    }
  }
}
