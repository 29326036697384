
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'FooterMain',
  components: {
    LazyHydrate,
    UiAddressFooterList: () => import('@/components/ui/address/FooterList')
  }
}
