
import { hydrateWhenIdle, hydrateWhenVisible } from 'vue-lazy-hydration'
import HeaderNavigation from '@/components/HeaderNavigation'

export default {
  name: 'DefaultLayout',
  components: {
    HeaderNavigation,
    FooterMain: hydrateWhenVisible(() => import('@/components/FooterMain')),
    FooterBottom: hydrateWhenVisible(() => import('@/components/FooterBottom')),
    ScrollProgress: hydrateWhenIdle(() => import('@/components/widgets/ScrollProgress'), {timeout: 3000}),
    LiveChat: hydrateWhenIdle(() => import('@/components/widgets/LiveChat'), {timeout: 3000})
  },
  data () {
    return {
      performanceScore: 'Loading...'
    }
  },
  head () {
    let normalisedPath = this.$route.path
    normalisedPath = normalisedPath.replace(/\/$/, '')
    normalisedPath = (normalisedPath.endsWith('/') ? normalisedPath.slice(0, -1) : normalisedPath)

    normalisedPath = normalisedPath.includes('/tags/') ? normalisedPath.slice(0, normalisedPath.indexOf('/tags/')) : normalisedPath

    return {
      titleTemplate: '%s - AYKO',
      link: [
        {
          rel: 'canonical',
          href: normalisedPath ? (this.$config.NUXT_URL + normalisedPath) : (this.$config.NUXT_URL + '/')
        }
      ]
    }
  },
  mounted () {
    if (typeof window !== 'undefined') {
      window.history.scrollRestoration = 'auto';
      window.addEventListener('load', () => {
        if (typeof performance !== 'undefined') {
          const duration = (Math.floor((performance.timing.domComplete - performance.timing.navigationStart) / 100) * 100)
            .toLocaleString()
          this.performanceScore = `Loaded in ${duration}ms`
        }
      })
    }
  },
}
