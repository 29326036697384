// eslint-disable-next-line import/namespace
import { joinURL, encodeParam , encodePath } from 'ufo'
import { createOperationsGenerator } from '@nuxt/image/dist/runtime'
const operationsGenerator = createOperationsGenerator({
  keyMap: {
    format: 'f',
    fit: 'fit',
    width: 'w',
    height: 'h',
    quality: 'q',
    position: 'position'
  },
  joinWith: '/',
  formatter: (key, val) => encodeParam(key) + '_' + encodeParam(val)
})

const isSVG = (src) => {
  return encodePath(src).endsWith('.svg')
}

export const getImage = (src, { modifiers, baseURL = '/resize' } = {}) => {
  const params = operationsGenerator(modifiers)
  if (!params || isSVG(src)) {
    return {
      url: joinURL(baseURL, encodePath(src))
    }
  } else {
    return {
      url: joinURL(baseURL, params, encodePath(src))
    }
  }
}
export const validateDomains = true
