
import { mapGetters } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'HeaderNavigation',
  components: {
    LazyHydrate
  },
  data () {
    return {
      logoBlack: false,
      shadow: false,
      top: 0
    }
  },
  computed: {
    ...mapGetters({
      isMenuOpen: 'ui/isMenuOpen',
      isMenuUnpinned: 'ui/isMenuUnpinned'
    })
  },
  watch: {
    $route() {
      this.$store.dispatch('ui/menuClose')
      const openDropdowns = Array.from(this.$el.getElementsByClassName('show'))
      for (let i = 0; i <= (openDropdowns.length - 1 ); i++) {
        openDropdowns[i].classList.remove('show')
      }
    }
  },
  beforeMount () {
    window.addEventListener('scroll', this.setMenuPinnedState)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.setMenuPinnedState)
  },
  mounted () {
    this.$nextTick(() => {
      this.setMenuPinnedState()
    })
  },
  methods: {
    toggleMenu () {
      this.$store.dispatch('ui/menuToggle')
    },
    closeMenu () {
      this.$store.dispatch('ui/menuClose')
    },
    setMenuPinnedState () {
      const pageTop = document.documentElement.scrollTop || document.body.scrollTop
      if (pageTop > 300 && this.top <= pageTop) {
        this.$store.dispatch('ui/menuUnpinned')
        this.shadow = false
        this.top = pageTop
      } else {
        this.shadow = (pageTop > 0)
        this.$store.dispatch('ui/menuPinned')
        this.top = pageTop
      }
    },
    toggleDropdown (event) {
      event.preventDefault()
      event.target.parentElement.classList.toggle('show')
      event.target.nextElementSibling.classList.toggle('show')
    }
  }
}
