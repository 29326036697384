import Vue from 'vue'
import hoverintent from 'hover-intent-vue/base.min'

let listen

Vue.directive('hover-intent', {
  bind (el) {
    listen = hoverintent(el,
      () => {
        el.classList.add('hoverintent')
        return { value: true, el }
      },
      () => {
        el.classList.remove('hoverintent')
        return { value: false, el }
      }
    )
  },
  unbind() {
    listen.remove()
  }
})
