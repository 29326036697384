import { render, staticRenderFns } from "./FooterMain.vue?vue&type=template&id=3ffbf423"
import script from "./FooterMain.vue?vue&type=script&lang=js"
export * from "./FooterMain.vue?vue&type=script&lang=js"
import style0 from "./FooterMain.vue?vue&type=style&index=0&id=3ffbf423&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiAddressFooterList: require('/home/node/app/components/ui/address/FooterList.vue').default})
